import { action, createStore } from "easy-peasy";

const store = createStore({
  tags: [],
  selectedTag: "All",
  setSelectedTag: action((state, payload) => {
    state.selectedTag = payload;
  }),
  addTag: action((state, payload) => {
    // only add todo to todos array if it doesn't already exist
    if (state.tags.includes(payload)) return;
    state.tags.push(payload);
  }),
  removeTag: action((state, payload) => {
    state.tags = state.tags.filter((tag) => tag !== payload);
  }),
});

export default store;
