import styled from "styled-components/macro";
import { QUERIES } from "../../constants";

const BgHeader = () => {
  return <StyledBgHeader role="presentation" />;
};

export default BgHeader;

const gradient = `linear-gradient(to top, #5f69de, #6a73e5, #747ded, #7f88f4, #8992fb);`;

const StyledBgHeader = styled.div`
  background-image: url("https://framerusercontent.com/images/bpzFzDMLHucyCw0KQxmiglkch0.png");
  height: 210px;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  // background-color: var(--background);
  // border-bottom: 1px solid;
  // border-color: hsla(214, 17%, 51%, 0.2);
  @media ${QUERIES.tabletAndUp} {
    // background-image: ${gradient};
    border-radius: 0px 0px 0px 0;
    background-image: url("https://framerusercontent.com/images/bpzFzDMLHucyCw0KQxmiglkch0.png");
  }

  @media ${QUERIES.desktopAndUp} {
    height: 260px;
    // background-image: ${gradient};
    background-image: url("https://framerusercontent.com/images/bpzFzDMLHucyCw0KQxmiglkch0.png");
  }
`;
