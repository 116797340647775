import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";

export default function TagsSelect({ tags }) {
  const selectedTag = useStoreState((state) => state.selectedTag);
  const setSelectedTag = useStoreActions((actions) => actions.setSelectedTag);

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <div>
        <RadioGroup
          name="tags-select"
          aria-labelledby="tags-select-label"
          orientation="horizontal"
          sx={{ flexWrap: "wrap", gap: 1.5 }}
        >
          {tags.map((name) => {
            const checked = selectedTag === name;
            return (
              <Chip
                key={name}
                variant="plain"
                color={checked ? "primary" : "neutral"}
                size={"lg"}
                // startDecorator={
                //   checked && (
                //     <CheckIcon sx={{ zIndex: 1, pointerEvents: "none" }} />
                //   )
                // }
              >
                <Radio
                  variant="outlined"
                  color={checked ? "primary" : "neutral"}
                  disableIcon
                  overlay
                  label={name}
                  value={name}
                  checked={checked}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedTag(name);
                    }
                  }}
                />
              </Chip>
            );
          })}
        </RadioGroup>
      </div>
    </Box>
  );
}
