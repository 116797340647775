import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { COLORS, QUERIES, WEIGHTS } from "../../constants";
import BgHeader from "../BgHeader";

const Header = () => {
  return (
    <>
      <BgHeader />
      <HeaderContainer>
        <HeaderContent>
          <StyledLink aria-label="financefinds - Home" to="/">
            {/* <Logo aria-hidden="true" /> */}
            <h2>FinanceFinds</h2>
          </StyledLink>

          {/* <ToggleDarkMode /> */}
        </HeaderContent>
        <Desc>Best personal finance resources for beginners</Desc>
      </HeaderContainer>
    </>
  );
};

export default Header;

const Desc = styled.p`
  max-width: 40rem;
  font-size: 1.3rem;
  font-weight: ${WEIGHTS.bold};
  margin-top: 1.1rem;
  margin-bottom: 2.25rem;
  line-height: 1.35;
  color: var(--heading);
  @media ${QUERIES.tabletAndUp} {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

const StyledLink = styled(Link)`
  outline: none;
  border-radius: 1px;
  transition: all 100ms;
  font-size: 0.75rem;
  font-weight: ${WEIGHTS.normal};
  color: ${COLORS.gray[400]};
  &:focus {
    outline: 2px solid white;
    outline-offset: 4px;
  }
  text-decoration: none;
  &:active {
    outline: none;
  }

  @media ${QUERIES.tabletAndUp} {
    font-size: 0.9rem;
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  width: var(--maxWidth);
  max-width: 100%;
  padding: 0 24px;
  margin: 0 auto;

  @media ${QUERIES.tabletAndUp} {
    padding: 0 40px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  flex-direction: column;

  @media ${QUERIES.tabletAndUp} {
    margin-top: 42px;
  }

  @media ${QUERIES.desktopAndUp} {
    margin-top: 30px;
  }
`;
