import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import axios from "axios";
import { useMutation } from "react-query";
import styled from "styled-components/macro";
import { COLORS, QUERIES } from "../../constants";

export default function SignupForm() {
  const addNewSubscription = useMutation({
    mutationFn: (event) => {
      event.preventDefault();
      const data = new FormData(event.target);
      const subscription = {
        email: data.get("email"),
      };
      console.log("subscription: ", subscription);
      return axios.post("/api/subscriptions", subscription);
    },
  });

  return (
    <>
      <Card>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LogoContainer>
            <img
              src={`https://cdn-icons-png.flaticon.com/512/214/214929.png`}
              alt={"logo"}
            />
          </LogoContainer>
          <Title>Get a Free personal finance cheatsheet via email</Title>
        </div>
        <div>
          <CompanyName>Simplify your finances!</CompanyName>
        </div>
        <Form
          handleSubmit={addNewSubscription.mutate}
          isSuccess={addNewSubscription.isSuccess}
          isPending={addNewSubscription.isPending}
          isLoading={addNewSubscription.isLoading}
          isError={addNewSubscription.isError}
        />
      </Card>
    </>
  );
}

const inputStyling = {
  "--Input-radius": "8px",
  "--Input-gap": "4px",
  "--Input-placeholderOpacity": 0.5,
  "--Input-focusedThickness": "1px",
  "--Input-minHeight": "43px",
  "--Input-paddingInline": "12px",
  "--Input-decoratorChildHeight": "37px",
};
const Form = ({ handleSubmit, isSuccess, isPending, isError, isLoading }) => {
  return (
    <div>
      <StyledForm onSubmit={handleSubmit}>
        <Input
          type="email"
          name="email"
          placeholder="Enter your email..."
          required
          endDecorator={
            <Button
              sx={(theme) => ({
                background: `${COLORS.violet[200]}`,
                "&:hover": {
                  background: `${COLORS.violet[300]}`,
                },
              })}
              type="submit"
              loading={isLoading || isPending}
            >
              Submit
            </Button>
          }
          sx={inputStyling}
        />
      </StyledForm>

      {isError && <StyledSpan>Something went wrong 😢</StyledSpan>}

      {isSuccess && <StyledSpan>Thank you for subscribing! ✅</StyledSpan>}

      {isLoading && <StyledSpan>Subscribing... ⏳</StyledSpan>}

      {!isSuccess && !isPending && !isError && !isLoading && (
        <StyledSpan>No spam, unsubscribe at any time.</StyledSpan>
      )}
    </div>
  );
};

function isValidEmail(email) {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(email);
}

const StyledSpan = styled.span`
  color: var(--text);
  text-align: center;
  margin-left: 10px;
  font-size: 0.9rem;
`;

const StyledForm = styled.form`
  margin: 10px 0;
  @media ${QUERIES.tabletAndUp} {
    margin: 10px 0 10px 0;
  }
`;

const Title = styled.h2`
  color: var(--heading);
  font-size: ${21 / 18 + "rem"};
  line-height: 1.35;
  margin: 8px 0;
`;

const Card = styled.div`
  width: 100%;
  border-radius: var(--borderRadius);
  background-color: var(--background);
  padding: 35px 20px 20px 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);

  &:hover ${Title} {
    // color: ${COLORS.gray[300]};
    text-decoration: underline;
    -webkit-text-decoration-color: hsl(235, 69%, 61%);
    text-decoration-color: hsl(235, 69%, 61%);
    -webkit-text-decoration-style: wavy;
    text-decoration-style: wavy;
    tetx-decoration-thickness: 2px;
  }

  &:focus {
    outline-color: ${COLORS.violet[200]};
    outline-offset: 4px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  transform: translateY(-60px);
  width: 44px;
  height: auto;
  background: ${(props) => props.logoBackground || "transparent"};
  display: grid;
  place-items: center;

  & > img {
    border-radius: var(--borderRadius);
  }
`;

const CompanyName = styled.span`
  color: var(--text);
  font-size: 1.15rem;
  transition: all 200ms ease-in;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding: 5px 0;
`;
