import React from "react";
import styled from "styled-components/macro";
import { COLORS, WEIGHTS } from "../../constants";
const ListingItemCard = ({
  to,
  title,
  description,
  category,
  pricing,
  website,
  type,
  children,
}) => {
  if (children) {
    return { children };
  }
  return (
    <>
      <Card href={website} target="_blank">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <LogoContainer style={{ flex: 1 }}>
              {website && (
                <img
                  src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${website}&size=128`}
                  alt={"logo"}
                />
              )}
            </LogoContainer>
            {/* <a
              href={website}
              target="_blank"
              style={{
                marginLeft: "auto",
                marginTop: "-24px",
                marginBottom: "4px",
                textDecoration: "none",
              }}
            >
              Visit
            </a> */}
            {/* <Button
                component="a"
                href="#as-link"
                startDecorator={<OpenInNew />}
                size="sm"
                variant="plain"
                sx={{
                  marginLeft: "auto",
                  marginTop: "-24px",
                  marginBottom: "4px",
                  textDecoration: "none",
                  padding: "4px 8px",
                }}
              >
                Visit site
              </Button> */}
          </div>
          <Title>{title}</Title>
        </div>
        <div>
          <CompanyName>{description}</CompanyName>
        </div>
        <Subtitle>
          <span style={{ display: "flex", gap: "8px" }}>
            <SubtitleSpan>{category}</SubtitleSpan>
            <SubtitleTypeSpan>{type}</SubtitleTypeSpan>
          </span>
          <span>
            <span
              style={{
                color: COLORS.violet[200],
                textTransform: "capitalize",
              }}
            >
              {pricing}
            </span>
          </span>
        </Subtitle>
      </Card>
    </>
  );
};

export default ListingItemCard;

const getEmojiByType = (type) => {
  switch (type) {
    case "book":
      return "📕";
    case "video":
      return "📹";
    case "website":
      return "💻";
    case "app":
      return "📱";
    case "image":
      return "🖼️";
    default:
      return "";
  }
};

const Title = styled.h2`
  color: var(--heading);
  font-size: ${21 / 18 + "rem"};
  line-height: 1.35;
  margin: 8px 0;
  text-transform: capitalize;
`;

const Card = styled.a`
  width: 100%;
  border-radius: var(--borderRadius);
  background-color: var(--background);
  padding: 35px 20px 20px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);

  &:hover ${Title} {
    // color: ${COLORS.gray[300]};
    text-decoration: underline;
    -webkit-text-decoration-color: hsl(235, 69%, 61%);
    text-decoration-color: hsl(235, 69%, 61%);
    -webkit-text-decoration-style: wavy;
    text-decoration-style: wavy;
    tetx-decoration-thickness: 2px;
  }

  &:focus {
    outline-color: ${COLORS.violet[200]};
    outline-offset: 4px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  -webkit-transform: translateY(-62px);
  -ms-transform: translateY(-62px);
  transform: translateY(-62px);
  width: 56px;
  height: 56px;
  background: transparent;
  display: grid;
  place-items: center;
  background: #fff;
  padding: 7px;
  border-radius: var(--borderRadius);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);

  & > img {
    border-radius: var(--borderRadius);
  }
`;

const PostedAtAndContractContainer = styled.p`
  display: flex;
  gap: 12px;
  color: var(--text);
  font-size: 1rem;
  transition: all 200ms ease-in;
`;

const CompanyName = styled.span`
  color: var(--text);
  font-size: 1rem;
  transition: all 200ms ease-in;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const Subtitle = styled.h3`
  font-size: ${14 / 15 + "rem"};
  color: ${COLORS.gray[300]};
  margin-top: 1.8rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: ${WEIGHTS.normal};
`;

const SubtitleSpan = styled.span`
  padding: 0.5px 8px;
  border-radius: var(--borderRadius);
  background-color: ${COLORS.gray[100]};
  color: ${COLORS.violet[200]};
`;

const SubtitleTypeSpan = styled.span`
  color: ${COLORS.green[100]};
  padding: 0.5px 8px;
  border-radius: var(--borderRadius);
  background-color: ${COLORS.gray[100]};
`;
