export const COLORS = {
  white: "hsl(0, 0%, 100%)",
  lightWhite: "hsl(0, 12.3%, 88.8%)",
  gray: {
    100: "hsl(210, 22%, 96%)",
    200: "hsl(212, 23%, 69%)",
    300: "hsl(215.3, 15%, 44.3%)",
    400: "hsl(214.3, 25.2%, 27.3%)",
  },
  violet: {
    100: "hsl(235, 82%, 77%)",
    200: "hsl(235, 69%, 61%)",
    300: "hsl(235.3, 60.9%, 54.9%)",
  },
  dark: {
    100: "hsl(217.9, 16.8%, 22.2%)",
    200: "hsl(220, 29%, 10%)",
    300: "hsla(217.9, 16.8%, 22.2%, 0.95)",
  },
  green: {
    100: "hsl(155.3, 86.8%, 35.7%)",
  },
};

export const WEIGHTS = {
  normal: 400,
  bold: 700,
};

export const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const QUERIES = {
  tabletAndUp: `(min-width: ${BREAKPOINTS.sm / 16}rem)`,
  laptopAndUp: `(min-width: ${BREAKPOINTS.md / 16}rem)`,
  desktopAndUp: `(min-width: ${BREAKPOINTS.lg / 16}rem)`,
};
