import { Analytics } from "@vercel/analytics/react";
import { StoreProvider } from "easy-peasy";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import App from "./components/App";
import store from "./store";

const queryClient = new QueryClient({
  defaultOptions: {
    // queries: {
    //   cacheTime: 5 * 60 * 1000, // 5 minutes
    // },
  },
});

const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
});

persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Analytics />
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>,
  document.getElementById("root")
);
