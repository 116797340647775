import React from "react";
import styled from "styled-components";
import { COLORS, QUERIES } from "../../constants";
//  A dropdown component for categories
// options will be passed in a list in props
//   handleChange will be passed in a function in props
const FilterDropdown = ({
  options,
  dropdownChangeHandler,
  name,
  width,
  filterValue,
}) => {
  // console.log("filterValue: ", filterValue);
  // console.log("options: ", options);
  return (
    <FilterDropdownContainer width={width}>
      {/* <Label htmlFor="category">Filter by category</Label> */}
      <Select
        name="filter-dropdown"
        aria-label={`Filter by ${name}`}
        placeholder={`Filter by ${name}`}
        data-test={`filter-dropdown-${name}`}
        onChange={dropdownChangeHandler}
        value={filterValue}
        key={name}
      >
        <option value="">All {name}</option>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </FilterDropdownContainer>
  );
};
export default React.memo(FilterDropdown);

const Label = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
  margin-bottom: 8px;
`;

const FilterDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${(props) => props.width};
  @media ${QUERIES.tabletAndUp} {
    width: 31.5%;
  }
`;

const Select = styled.select`
  border: none;
  flex-shrink: 1;
  width: 100%;
  border-radius: var(--borderRadius);
  caret-color: ${COLORS.violet[200]};
  background-color: var(--background);
  color: var(--inputText);
  height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px 12px;
  cursor: pointer;
  padding-left: 12px;
  border-radius: var(--borderRadius);
  border: 1px solid;
  border-color: hsla(214, 17%, 51%, 0.2);
  caret-color: ${COLORS.violet[200]};
  background-color: var(--background);
  @media ${QUERIES.tabletAndUp} {
    width: 100%;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.violet[200]};
  }
`;
