import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { QUERIES } from "../../constants";
import useListings from "../../hooks/useListings";

import Button from "@mui/joy/Button";
import { useStoreState } from "easy-peasy";
import Checkbox from "../Checkbox";
import Container from "../Container";
import Header from "../Header";
import ListingItemCard from "../ListingItemCard/ListingItemCard";
import Loader from "../Loader/Loader";
import SignupForm from "../Newsletter/SignupForm";
import FilterDropdown from "../SearchBar/FilterDropdown";
import TagsSelect from "../SearchBar/TagsSelect";

const DEFAULT_ITEMS_PER_PAGE = 9;
const Listings = () => {
  const [loadMore, setLoadMore] = React.useState(DEFAULT_ITEMS_PER_PAGE);
  const { status, data, error } = useListings();
  const [filtering, setFiltering] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [pricing, setPricing] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [filterReset, setFilterReset] = useState(false);
  const selectedTag = useStoreState((state) => state.selectedTag);

  useEffect(() => {
    setPricing("");
    setCategory("");
    setType("");
    return () => setFilterReset(false);
  }, [filterReset]);

  useEffect(() => {
    async function getDataReady() {
      console.log("selectedTag: ", selectedTag);
      // filter data based on selected tags
      if (selectedTag) {
        setFiltering(true);
        const filteredData = await filterData({ selectedTag }, data);
        setFilteredData(filteredData);
        setFiltering(false);
        setLoadMore(DEFAULT_ITEMS_PER_PAGE);
      } else {
        console.log("in else");
        setFilteredData(data);
      }
    }
    getDataReady();
  }, [data, selectedTag]);

  return (
    <>
      <Header />
      <Main>
        <Container>
          <FilterBar>
            <TagsSelect tags={getCategories(data)} />
          </FilterBar>

          <>
            <Wrapper data-test="listing-items-list">
              {status === "loading" || filtering ? (
                <LoadingContainer>
                  <Loader />
                </LoadingContainer>
              ) : status === "error" ? (
                <span>Error: {error.message}</span>
              ) : (
                (filteredData || data)
                  ?.slice(0, loadMore)
                  .map((listingItem, index) => {
                    if (
                      index ===
                      getSignUpFormPosition(loadMore, filteredData.length)
                    ) {
                      return <SignupForm />;
                    }
                    return (
                      <ListingItemCard
                        to={`/listingItem/${listingItem.id}`}
                        key={listingItem.id}
                        pricing={listingItem.pricing}
                        category={listingItem.category}
                        description={listingItem.description}
                        title={listingItem.title}
                        website={listingItem.website}
                        type={listingItem.type}
                      />
                    );
                  })
              )}
            </Wrapper>
            <ButtonContainer>
              <LoadMoreListingsButton
                filteredDataLength={filteredData?.length}
                loadMore={loadMore}
                setLoadMore={setLoadMore}
                setFiltering={setFiltering}
              />
            </ButtonContainer>
          </>
        </Container>
      </Main>
    </>
  );
};

export default Listings;

const getSignUpFormPosition = (loadMore, dataLength) => {
  if (dataLength > loadMore) {
    return loadMore - 2;
  } else {
    return dataLength;
  }
};

const filterData = async (filterBy, data) => {
  console.log("In filterData: ", filterBy, data);

  if (!data) {
    return [];
  }

  await delay(Math.random() * 400 + 250);

  let filteredData = [...data];

  if (filterBy.pricing && filterBy.pricing.trim()) {
    filteredData = filteredData.filter((item) =>
      item.pricing
        ?.toLowerCase()
        ?.includes(filterBy.pricing.trim().toLowerCase())
    );
  }

  if (filterBy.category && filterBy.category.trim()) {
    filteredData = filteredData.filter((item) =>
      item.category
        ?.toLowerCase()
        ?.includes(filterBy.category.trim().toLowerCase())
    );
  }

  if (filterBy.type && filterBy.type.trim()) {
    filteredData = filteredData.filter((item) =>
      item.type?.toLowerCase()?.includes(filterBy.type.trim().toLowerCase())
    );
  }

  if (filterBy.selectedTag && filterBy.selectedTag.trim()) {
    if (filterBy.selectedTag.trim().toLowerCase() !== "all") {
      filteredData = filteredData.filter((item) =>
        item.category
          ?.toLowerCase()
          ?.includes(filterBy.selectedTag.trim().toLowerCase())
      );
    }
  }

  return filteredData;
};

const LoadMoreListingsButton = ({
  filteredDataLength,
  loadMore,
  setLoadMore,
  setFiltering,
}) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    await delay(Math.random() * 400 + 250);
    setLoadMore(loadMore + 9);
    setLoading(false);
  };

  if (filteredDataLength > loadMore) {
    return (
      <Button
        aria-label="Load more listingItems"
        size="lg"
        data-test="load-more-btn"
        onClick={handleClick}
        loading={loading}
        variant="solid"
        loadingPosition="start"
      >
        Load more
      </Button>
    );
  } else if (filteredDataLength === 0) {
    return <p>No listings found. Try changing the filters.</p>;
  } else {
    return <p>That's all folks!</p>;
  }
};

const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 35px;
  border-radius: 5px;
  width: 100%;
  border-radius: var(--borderRadius);
  background-color: var(--background);
  padding: 14px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
  gap: 8px;
  flex-wrap: wrap;
  @media ${QUERIES.tabletAndUp} {
    margin-bottom: 32px;
    justify-content: flex-start;
    gap: 20px;
    padding: 18px;
  }
`;

const Main = styled.main``;

const NoListings = styled.div``;

const LoadingContainer = styled.div`
  display: grid;
  place-items: center;
`;

const Wrapper = styled.div`
  //   display: grid;
  //   margin-top: 32px;
  //   grid-auto-rows: minmax(30px, auto);
  //   grid-gap: 50px;
  //   padding-bottom: 40px;
  //   grid-template-columns: 1fr 1fr;
  //   grid-auto-rows: minmax(30px, auto);
  //   grid-auto-columns: 50px;
  //   grid-column-gap: 20px;
  //   grid-row-gap: 60px;
  //   margin-top: 45px;
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  gap: 40px;
  @media ${QUERIES.tabletAndUp} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(30px, auto);
    grid-auto-columns: 50px;
    grid-column-gap: 20px;
    grid-row-gap: 60px;
    margin-top: 45px;
  }

  @media ${QUERIES.desktopAndUp} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(30px, auto);
    grid-column-gap: 30px;
    grid-row-gap: 50px;
    margin-top: 40px;
    grid-template-rows: 1fr 1fr;
  }
`;

// const LoadMoreButton = styled(Button)``;

const ButtonContainer = styled.div`
  text-align: center;
  padding-bottom: 7%;
  @media ${QUERIES.desktopAndUp} {
    padding-bottom: 3%;
  }
`;

function getTypes(data) {
  return data ? [...new Set(data.map((listingItem) => listingItem.type))] : [];
}

function getCategories(data) {
  const categories = data
    ? [...new Set(data.map((listingItem) => listingItem.category))]
    : [];
  return ["All", ...categories];
}

function FilterArea({
  data,
  Set,
  categoryDropdownChangeHandler,
  typeDropdownChangeHandler,
  checkboxHandler,
  pricing,
  category,
  type,
  resetFilters,
}) {
  return (
    <FilterBar>
      <FilterDropdown
        options={
          data
            ? [...new Set(data.map((listingItem) => listingItem.category))]
            : []
        }
        dropdownChangeHandler={categoryDropdownChangeHandler}
        name="Catgories"
        width={"60%"}
        filterValue={category}
        key="category"
      />
      <FilterDropdown
        options={
          data ? [...new Set(data.map((listingItem) => listingItem.type))] : []
        }
        dropdownChangeHandler={typeDropdownChangeHandler}
        name="Types"
        width="36%"
        filterValue={type}
        key="type"
      />
      <Checkbox
        onChange={checkboxHandler}
        checked={pricing}
        text="Free Only"
        textWidth="65px"
        aria-label="Free only"
        data-test="is-free-checkbox"
      />
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault();
          resetFilters(); // Call the resetFilters function
        }}
        style={{
          textDecoration: "none",
          marginRight: "10px",
        }}
      >
        reset filters
      </a>
    </FilterBar>
  );
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
